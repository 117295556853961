var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row content-header"},[_c('div',{staticClass:"content-header-left mb-2 col-md-9 col-12"},[_c('div',{staticClass:"row breadcrumbs-top"},[_c('div',{staticClass:"col-12"},[_c('h2',{staticClass:"content-header-title float-left pr-1 mb-0"},[_c('feather-icon',{staticClass:"mr-2",attrs:{"icon":"ArrowLeftIcon","size":"24"},on:{"click":_vm.goBack}}),_vm._v("Vaccination Entry ")],1),_vm._m(0)])])])]),_c('validation-observer',{ref:"simpleRules"},[_c('b-form',{attrs:{"action":"apps-data-entry-list","method":"post"},on:{"submit":_vm.addVaccine}},[_c('b-card',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Date of Vaccination"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('validation-provider',{attrs:{"name":"Entry Date","rules":{ required: true }},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"mb-1",attrs:{"menu-class":"w-100","calendar-width":"100%"},model:{value:(_vm.FormData.entry_date),callback:function ($$v) {_vm.$set(_vm.FormData, "entry_date", $$v)},expression:"FormData.entry_date"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Vaccine"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"Select Vaccine","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.vaccineList,"label":"name","clearable:false":""},on:{"input":_vm.setRouteType},model:{value:(_vm.FormData.vaccine_id),callback:function ($$v) {_vm.$set(_vm.FormData, "vaccine_id", $$v)},expression:"FormData.vaccine_id"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Route")]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"label":"routeType","type":"text","value":_vm.routeType,"disabled":""},model:{value:(_vm.routeType),callback:function ($$v) {_vm.routeType=$$v},expression:"routeType"}})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v("Unit")]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('b-form-input',{attrs:{"label":"unit","type":"text","value":_vm.unit,"disabled":""},model:{value:(_vm.unit),callback:function ($$v) {_vm.unit=$$v},expression:"unit"}})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Select Batch No"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":" Select Batch No","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"options":_vm.batchList,"label":"batch_no","clearable:false":""},on:{"input":_vm.getBatchDetails},model:{value:(_vm.FormData.batch_no),callback:function ($$v) {_vm.$set(_vm.FormData, "batch_no", $$v)},expression:"FormData.batch_no"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" No. of Birds"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"No. of Birds","rules":{
										required: true,
										max_value: this.flockData.alive_birds,
									}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Route","type":"text","name":"birds_quantity"},model:{value:(_vm.FormData.birds_quantity),callback:function ($$v) {_vm.$set(_vm.FormData, "birds_quantity", $$v)},expression:"FormData.birds_quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"5"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Total Quantity")]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"Total Quantity"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Total Quantity","type":"text","name":"total quantity","disabled":""},model:{value:(_vm.batchDetails.quantity),callback:function ($$v) {_vm.$set(_vm.batchDetails, "quantity", $$v)},expression:"batchDetails.quantity"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{staticStyle:{"align-self":"center"},attrs:{"md":"1"}},[(_vm.unit === "ml")?_c('div',{staticClass:"form-label-group"},[_vm._v("ml")]):(_vm.unit === "Dose")?_c('div',{staticClass:"form-label-group"},[_vm._v("Dose")]):_vm._e()]),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Quantity administered"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]},proxy:true}])},[_c('div',{staticClass:"form-label-group"},[_c('validation-provider',{attrs:{"name":"Quantity administered","rules":{
										required: true,
										max_value: this.batchDetails.quantity,
										regex: RegExp(/^\d+(\.\d{1,2})?$/),
									}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
									var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Quantity administered","type":"text","name":"quantity_administered"},model:{value:(_vm.FormData.quantity_administered),callback:function ($$v) {_vm.$set(_vm.FormData, "quantity_administered", $$v)},expression:"FormData.quantity_administered"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)])],1),_c('b-col',{attrs:{"md":"12"}},[_c('b-button',{staticClass:"mr-1 mb-3",attrs:{"type":"submit","variant":"primary","value":"Submit"}},[_vm._v(" Submit ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"breadcrumb-wrapper"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item active"},[_c('span',{attrs:{"aria-current":"location"}},[_vm._v(" Add ")])])])])}]

export { render, staticRenderFns }